import React, { useState } from 'react';
import Layout from '../../components/layout';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faClock, faCheckCircle, faCalendar, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/Button';
import LessonList from '../../components/LessonList';

const LocationPlaceholder = () => {
  const [activePage, setActivePage] = useState('details');
  const [activeLesson, setActiveLesson] = useState('preschool');

  const preschoolLessons = [
    {
      name: 'Preschool Group Lessons',
      image: (
        <StaticImage
          src="../../images/lessons/preschool.webp"
          alt="Preschool Group Lessons"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Children Aged 3-4 Years',
        },
        {
          name: 'Maximum of 4 Students Per Group',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
      ],
    },
    {
      name: 'Preschool Private 1 - 1',
      image: (
        <StaticImage
          src="../../images/lessons/preschool.webp"
          alt="Preschool Private 1 - 1"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Children Aged 3-4 Years',
        },
        {
          name: '1 Student Per Lesson',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
        {
          name: 'Holyrood Secondary',
        },
      ],
    },
    {
      name: 'Preschool Private 1 - 2',
      image: (
        <StaticImage
          src="../../images/lessons/preschool.webp"
          alt="Preschool Private 1 - 1"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Children Aged 3-4 Years',
        },
        {
          name: '2 Students Per Lesson',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
        {
          name: 'Holyrood Secondary',
        },
      ],
    },
  ];

  const adultLessons = [
    {
      name: 'Adult Group Lessons',
      image: (
        <StaticImage
          src="../../images/lessons/adult.webp"
          alt="Adult Group Lessons"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Students aged 13+',
        },
        {
          name: 'Maximum of 4 Students Per Group',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
      ],
    },
    {
      name: 'Adult Private 1 - 1',
      image: (
        <StaticImage
          src="../../images/lessons/adult.webp"
          alt="Adult Private 1 - 1"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Students aged 13+',
        },
        {
          name: '1 Student Per Lesson',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
        {
          name: 'Holyrood Secondary',
        },
      ],
    },
    {
      name: 'Adult Private 1 - 2',
      image: (
        <StaticImage
          src="../../images/lessons/adult.webp"
          alt="Adult Private 1 - 1"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Students aged 13+',
        },
        {
          name: '2 Students Per Lesson',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
        {
          name: 'Holyrood Secondary',
        },
      ],
    },
  ];

  const childLessons = [
    {
      name: 'Child Group Lessons',
      image: (
        <StaticImage
          src="../../images/lessons/children.webp"
          alt="Child Group Lessons"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Children Aged 3-4 Years',
        },
        {
          name: 'Maximum of 4 Students Per Group',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
      ],
    },
    {
      name: 'Child Private 1 - 1',
      image: (
        <StaticImage
          src="../../images/lessons/children.webp"
          alt="Child Private 1 - 1"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Children Aged 3-4 Years',
        },
        {
          name: '1 Student Per Lesson',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
        {
          name: 'Holyrood Secondary',
        },
      ],
    },
    {
      name: 'Child Private 1 - 2',
      image: (
        <StaticImage
          src="../../images/lessons/children.webp"
          alt="Child Private 1 - 1"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Children Aged 3-4 Years',
        },
        {
          name: '2 Students Per Lesson',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
        {
          name: 'Holyrood Secondary',
        },
      ],
    },
  ];

  const [lessons, setLessons] = useState(preschoolLessons);

  const changeLesson = (id) => {
    switch (id) {
      case 'child':
        setLessons(childLessons);
        break;
      case 'adult':
        setLessons(adultLessons);
        break;

      default:
        setLessons(preschoolLessons);
    }
    setActiveLesson(id);
  };

  return (
    <Layout styles="locations" title="Holyrood Secondary School">
      <div className="location-page">
        <div className="location-back">
          <Link to="/locations/">
            <FontAwesomeIcon icon={faArrowLeft} /> Back to Swimming Pools
          </Link>
        </div>
        <div className="location-sidebar">
          <div className="location-images">
            <div className="location-image-container">
              <StaticImage
                src="../../images/pools/one.webp"
                height={500}
                width={500}
                alt="Swimming Pool"
                transformOptions={{
                  cropFocus: 'bottom',
                  fit: 'cover',
                }}
              />
            </div>
            <div className="location-alternate-images">
              <StaticImage
                src="../../images/pools/one.webp"
                height={100}
                width={100}
                alt="Swimming Pool"
                transformOptions={{
                  cropFocus: 'bottom',
                  fit: 'cover',
                }}
              />
              <StaticImage
                src="../../images/pools/one.webp"
                height={100}
                width={100}
                alt="Swimming Pool"
                transformOptions={{
                  cropFocus: 'bottom',
                  fit: 'cover',
                }}
              />
              <StaticImage
                src="../../images/pools/one.webp"
                height={100}
                width={100}
                alt="Swimming Pool"
                transformOptions={{
                  cropFocus: 'bottom',
                  fit: 'cover',
                }}
              />
            </div>
          </div>
          <div className="location-opening-times">
            <h2>Opening Times</h2>
            <div className="contact-day-container">
              <div className="contact-day">Monday</div>
              <div className="contact-time">
                <FontAwesomeIcon icon={faClock} />
                17:00 - 21:00
              </div>
            </div>
            <div className="contact-day-container">
              <div className="contact-day">Thursday</div>
              <div className="contact-time">
                <FontAwesomeIcon icon={faClock} />
                16:15 - 18:15
              </div>
            </div>
            <div className="contact-day-container">
              <div className="contact-day">Saturday</div>
              <div className="contact-time">
                <FontAwesomeIcon icon={faClock} />
                09:00 - 13:00 (Ladies Only Session)
              </div>
            </div>
          </div>
        </div>
        <div className="location-data">
          <div className="location-menu">
            <Button className={activePage === 'details' ? 'active' : ''} onClick={() => setActivePage('details')} link>
              Details
            </Button>
            <Button className={activePage === 'lessons' ? 'active' : ''} onClick={() => setActivePage('lessons')} link>
              Lessons
            </Button>
            <Button
              className={activePage === 'directions' ? 'active' : ''}
              onClick={() => setActivePage('directions')}
              link
            >
              Directions
            </Button>
          </div>

          {activePage === 'details' && (
            <div className="location-details">
              <p>Information here about the swimming pool, etc..</p>
            </div>
          )}

          {activePage === 'lessons' && (
            <div className="location-lessons">
              <div className="location-sub-menu">
                <Button
                  className={activeLesson === 'preschool' ? 'active' : ''}
                  onClick={() => changeLesson('preschool')}
                  link
                >
                  Preschool
                </Button>
                <Button className={activeLesson === 'child' ? 'active' : ''} onClick={() => changeLesson('child')} link>
                  Child
                </Button>
                <Button className={activeLesson === 'adult' ? 'active' : ''} onClick={() => changeLesson('adult')} link>
                  Adult
                </Button>
              </div>
              <LessonList lessons={lessons} showLocation={false} showButtons={false} />
            </div>
          )}

          {activePage === 'directions' && (
            <div className="location-directions">
              <p>Details here about how to get there, where they should go when there, etc ...</p>
              <div className="direction-container">
                <div className="direction-title">Address:</div>
                <div className="direction-content">Holyrood Secondary School, 100 Dixon Road, Glasgow G42 8AU</div>
              </div>
              <div className="direction-container">
                <div className="direction-title">What3Words:</div>
                <div className="direction-content">///clever.hooks.rate</div>
              </div>
              <div className="direction-container">
                <div className="direction-title">Google Map:</div>
                <div className="direction-content">
                  <div className="location-map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1651.2554709085875!2d-4.252450855424985!3d55.83265094964776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4888472c13c5acbb%3A0xc82ddce4937c879d!2sHolyrood%20Secondary%20School!5e0!3m2!1sen!2suk!4v1651328510103!5m2!1sen!2suk"
                      width="100%"
                      height="100%"
                      style={{ border: 'none' }}
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LocationPlaceholder;
